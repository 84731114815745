import React from 'react';
import { createRoot } from 'react-dom/client';
import Widget from './components/Widget';

// Global initialization function
declare global {
  interface Window {
    SquarepasteWidget: {
      init: (container: HTMLElement, config: { clientId: string }) => void;
    };
  }
}

// Initialize widget
window.SquarepasteWidget = {
  init: (container: HTMLElement, config: { clientId: string }) => {
    const root = createRoot(container);
    root.render(<Widget clientId={config.clientId} />);
  }
}; 