import React, { useState, useEffect } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';

interface WidgetProps {
  clientId: string;
}

interface Task {
  title: string;
  description: string;
  priority: 'low' | 'medium' | 'high';
  attachments?: File[];
}

interface Message {
  content: string;
  attachments?: File[];
}

const Widget: React.FC<WidgetProps> = ({ clientId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState<'task' | 'message'>('task');
  const [task, setTask] = useState<Task>({
    title: '',
    description: '',
    priority: 'medium',
  });
  const [message, setMessage] = useState<Message>({
    content: '',
  });
  const [taskFiles, setTaskFiles] = useState<File[]>([]);
  const [messageFiles, setMessageFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Only show widget in Squarespace config mode
    const isConfigMode = window.location.pathname.includes('/config');
    setIsVisible(isConfigMode);
  }, []);

  const handleTaskSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'tasks'), {
        ...task,
        clientId,
        status: 'pending',
        createdAt: new Date(),
        // Handle file uploads separately
      });

      setTask({
        title: '',
        description: '',
        priority: 'medium',
      });
      setTaskFiles([]);
      alert('Task submitted successfully!');
    } catch (error) {
      console.error('Error submitting task:', error);
      alert('Failed to submit task. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMessageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'messages'), {
        ...message,
        clientId,
        createdAt: new Date(),
        // Handle file uploads separately
      });

      setMessage({
        content: '',
      });
      setMessageFiles([]);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isExpanded ? (
        <button
          onClick={() => setIsExpanded(true)}
          className="bg-black text-white p-4 rounded-full shadow-lg hover:bg-gray-800 transition-colors flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className="font-medium">New Request</span>
        </button>
      ) : (
        <div className="w-96 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden">
          {/* Header */}
          <div className="bg-black text-white px-4 py-3 flex justify-between items-center">
            <h2 className="text-lg font-medium">Client Portal</h2>
            <div className="flex items-center gap-2">
              <div className="flex gap-2">
                <button
                  onClick={() => setActiveTab('task')}
                  className={`px-3 py-1 rounded ${
                    activeTab === 'task' ? 'bg-white text-black' : 'text-white'
                  }`}
                >
                  New Task
                </button>
                <button
                  onClick={() => setActiveTab('message')}
                  className={`px-3 py-1 rounded ${
                    activeTab === 'message' ? 'bg-white text-black' : 'text-white'
                  }`}
                >
                  Message
                </button>
              </div>
              <button
                onClick={() => setIsExpanded(false)}
                className="ml-2 p-1 hover:bg-gray-800 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="p-4">
            {activeTab === 'task' ? (
              <form onSubmit={handleTaskSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Task Title
                  </label>
                  <input
                    type="text"
                    value={task.title}
                    onChange={(e) => setTask({ ...task, title: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    value={task.description}
                    onChange={(e) => setTask({ ...task, description: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md h-24"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Priority
                  </label>
                  <select
                    value={task.priority}
                    onChange={(e) => setTask({ ...task, priority: e.target.value as Task['priority'] })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Attachments
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => setTaskFiles(Array.from(e.target.files || []))}
                    className="w-full"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 transition disabled:opacity-50"
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Task'}
                </button>
              </form>
            ) : (
              <form onSubmit={handleMessageSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    value={message.content}
                    onChange={(e) => setMessage({ ...message, content: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md h-32"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Attachments
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => setMessageFiles(Array.from(e.target.files || []))}
                    className="w-full"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 transition disabled:opacity-50"
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Widget; 